import React, { Fragment } from 'react'
import SignUpFields from './signUpFields'
import Introduction2 from '../../components/global/introduction2'
import signupImage from '../../assets/images/signup.jpg'

const SignUp = () => {
    return (
        <Fragment>
            <Introduction2
                image={signupImage}
                children={<SignUpFields />}
            />
        </Fragment>
    )
}

export default SignUp