import React from 'react'
import { Fragment } from 'react'
import TicketsProgressFields from './ticketprogressfields'
import Introduction2 from '../../components/global/introduction2'
import ticketprogress from '../../assets/images/ticketprogress.jpg'

const TicketProgress = () => {
    return (
        <Fragment>
            <Introduction2
                image={ticketprogress}
                children={<TicketsProgressFields />}
            />
        </Fragment>
    )
}

export default TicketProgress