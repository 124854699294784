import React from 'react'
import './styles.scss'
import { Col, Container, Row } from 'react-bootstrap'
import cryptoImage from '../../../assets/images/cryptoImage.jpg'
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/customerService.json';

const RecoveryService = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='recoveryServiceWrapper'>
            <Container>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} className='recoveryTitlesContainer'>
                        <div className='recoverySubTitle' data-aos="fade-up" data-aos-duration="1000">
                            <p>EXCEPTIONALLY TRUSTWORTHY AND RELIABLE</p>
                        </div>

                        <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                            <p>
                                Rely on our unwavering commitment to trust and dependability when it comes to recovering lost funds.
                                We have successfully helped numerous victims worldwide reclaim millions of dollars' worth of stolen and
                                inaccessible cryptocurrencies, providing them with a sense of relief and enabling them to regain control
                                over their wealth.
                            </p>
                        </div>
                        <div className='recoverySubTitle' data-aos="fade-up" data-aos-duration="1000">
                            <p>EASING YOUR BURDEN THROUGH OUR VAST EXPERIENCE</p>
                        </div>
                        <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                            <p>
                                Let us alleviate the burden and stress you may be experiencing with our vast experience in
                                recovering crypto assets. Our team has partnered with renowned experts in the field and employees
                                advanced blockchain analysis techniques to trace and retrieve lost funds. With our guidance and support,
                                you can regain control over your assets and put the ordeal behind you.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} className='cryptoImageContainer' data-aos="fade-up" data-aos-duration="1000">
                        <Lottie
                            options={defaultOptions}
                            className="lottieFile"
                        />
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default RecoveryService