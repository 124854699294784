import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import './styles.scss'

const SubmitButton = ({ loading, title }) => {
    return (
        <Button type="submit" className={!loading ? 'submitButton' : 'loadingSubmitButton'}>
            {
                !loading ?
                    <span>{title}</span>
                    :
                    <Spinner animation="border" role="status" size="sm" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
            }
        </Button>
    )
}

export default SubmitButton