import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap';


const StyledAccordion = ({ title, data }) => {
    return (
        <div className='faqWrapper'>
            <div className='faqTitleContainer'>
                <p>{title}</p>
            </div>

            <div className='faqContainer'>
                <Container>
                    {
                        data.map(data => (
                            <div className='faq'>
                                <div className='faqQuestion'>
                                    {data.question}
                                </div>
                                <div className='faqAnswer'>
                                    {data.answer}
                                </div>
                            </div>
                        ))
                    }

                </Container>

            </div >
        </div >
    )
}

export default StyledAccordion