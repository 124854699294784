import React from 'react'
import './styles.scss'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from "react-router-dom"
import { AiFillSetting } from "react-icons/ai";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const UserDropdown = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();


    const logOutUserMenu = () => {
        cookie.remove('token');
        navigate("/");
    }

    return (
        <OverlayTrigger
            key='right'
            placement='right'
            overlay={
                <Tooltip id={`tooltip-botton`}>
                    <strong>Settings</strong>
                </Tooltip>
            }
        >
            <DropdownButton id="dropdown-basic-button" title={<AiFillSetting color='#fff' size={25} />}>
                <Dropdown.Item onClick={() => logOutUserMenu()}>LogOut</Dropdown.Item>
            </DropdownButton>
        </OverlayTrigger>

    )
}

export default UserDropdown