import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { links, loggedInLinks } from '../../../data'
import { Link } from 'react-router-dom'
import logo2 from '../../../assets/images/fintekrecoverysmall.png'
import Cookies from 'universal-cookie'

const Footer = () => {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container className='footerBootstrapContainer'>
                <div className='footerContainer'>
                    <div className='footerLinksContainer'>
                        <div className='footerBody'>
                            {
                                links.map(link => (
                                    <Link to={link.path} className='footerLink'>
                                        <p>{link.link}</p>
                                    </Link>
                                ))
                            }
                            {
                                cookie.get('token') && loggedInLinks.map(link => (
                                    <Link to={link.path} className='footerLink'>
                                        <p>{link.link}</p>
                                    </Link>
                                ))
                            }
                        </div>

                    </div>
                    <div className='copyrightContainer'>
                        <p>Copyright 2023 Crypto Recovery – All rights reserved</p>
                    </div>
                </div>
            </Container>

        </div>
    )
}

export default Footer