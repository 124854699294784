import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import FloatingListSection from '../../components/global/floatingsection'
import { floatingData } from '../../data'
import RecoveryService from '../../components/homepage/recoveryservice'
import Testimonials from '../../components/global/testimonials'

const HomePage = () => {

    return (
        <Fragment>

            <Introduction
                title1="RECOVER LOST FUNDS WITHOUT UPFRONT PAYMENT."
                mainTitle="REGAIN ACCESS TO LOST MONEY."
                title2="Let us help you recover your lost cryptocurrency without any initial payment required."
            />

            <FloatingListSection
                margin='64px 0px'
                data={floatingData}
            />

            <RecoveryService />

            <Testimonials
                title='REVIEWS'
            />

        </Fragment>
    )
}

export default HomePage