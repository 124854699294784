import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../../components/global/navbar'
import Footer from '../../components/global/footer'


function PublicLayout() {
    return (
        <div>
            <NavBar />
            <Outlet />
            <Footer />
        </div>
    )
}

export default PublicLayout