import React from 'react'
import './styles.scss'
import { services } from '../../../data'

const ServicesCards = () => {
    return (
        <div className='serviceWrapper'>
            <div className='serviceTitle' data-aos="fade-up" data-aos-duration="500">
                <p>Services</p>
            </div>

            <div class="timeline">

                {
                    services.map(service => (
                        <div class={`timeline__event  animated fadeInUp delay-3s timeline__event--type${service.type}`}>
                            <div class="timeline__event__icon ">
                            </div>
                            <div class="timeline__event__date">
                                {service.largeIcon}
                            </div>
                            <div class="timeline__event__content ">
                                <div class="timeline__event__title">
                                    {service.name}
                                </div>
                                <div class="timeline__event__description">
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        </div>

                    ))
                }
            </div>
        </div >
    )
}

export default ServicesCards