import React, { Fragment } from 'react'
import ProfileFields from './profileFields'
import Introduction2 from '../../components/global/introduction2'
import profile from '../../assets/images/profile.jpg'

const Profile = () => {
    return (
        <Fragment>
            <Introduction2
                image={profile}
                children={<ProfileFields />}
            />

        </Fragment>
    )
}

export default Profile