import { FaStar } from 'react-icons/fa';
import './styles.scss';

const Rating = ({ rating }) => {
    return (
        <div className="static-rating">
            {Array.from({ length: rating }).map((_, index) => (
                <FaStar key={index} className="star-icon" />
            ))}
        </div>
    );
};

export default Rating;
