import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import { floatingData, floatingData2 } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import AboutUsServices from '../../components/aboutus/services'
import './styles.scss'
import Testimonials from '../../components/global/testimonials'
import AboutUsDescription from '../../components/aboutus/aboutusdescripiton'

const AboutUs = () => {
    return (
        <Fragment>

            <Introduction
                title1="Reclaim Your Lost Funds without any Upfront Costs."
                mainTitle="Recover Lost Funds: No Advance Payment Needed."
                title2="Regain access to your lost money and cryptocurrency without the need for upfront payment.Restore your lost funds and regain control of your money and cryptocurrency with no upfront payment required.Regain access to your lost money and cryptocurrency in a secure and risk-free manner without any initial payment necessary."
            />

            <AboutUsServices />

            <FloatingListSection
                margin='64px 0px'
                data={floatingData}
            />

            <AboutUsDescription />

            <Testimonials
                title='WE’VE HELPED MANY PEOPLE'
                subTitle='RECOVER THEIR LOST FUNDS'
            />
        </Fragment>
    )
}

export default AboutUs