import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import StyledAccordion from '../../components/global/accordion'
import { moreData } from '../../data'
import FloatingContainer from '../../components/global/floatingContainer'

const More = () => {
    return (
        <Fragment>

            <Introduction
                title1="UNCOVERING THE PATH TO"
                mainTitle="BRING YOUR LOST FUNDS BACK TO LIFE"
                title2="When it comes to lost or inaccessible cryptocurrencies, we specialize in offering assistance for a diverse range of situations. Our expertise extends to addressing various challenges associated with missing funds and inaccessible digital assets. Some prevalent causes of cryptocurrency investment losses include:"
            />

            <StyledAccordion
                title='FAQ.'
                data={moreData}
            />

        </Fragment>
    )
}

export default More