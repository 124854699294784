import React from 'react'
import { Fragment } from 'react'
import MyTicketFields from './myticketFields'
import Introduction2 from '../../components/global/introduction2'
import ticket from '../../assets/images/ticket.jpg'
const MyTicket = () => {
    return (
        <Fragment>
            <Introduction2
                image={ticket}
                children={<MyTicketFields />}
            />
        </Fragment>
    )
}

export default MyTicket