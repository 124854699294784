import React, { Fragment } from 'react'
import './styles.scss'
import styled from 'styled-components';
import backgroundVideo from '../../../assets/video/backgroundVideo.mp4'
import { Container } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/scroll1.json';

const Introduction = ({
    title1,
    mainTitle,
    title2
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Fragment>
            <div className='introductionWrapper'>

                <Container className='introductionImageContainer'>
                    <div className='introductionFirstTitle' data-aos="fade-up" data-aos-duration="500">
                        <p>{title1}</p>
                    </div>
                    <div className='introductionMainTitle' data-aos="fade-up" data-aos-duration="1000">
                        <p>{mainTitle}</p>
                    </div>
                    <div className='introductionSecondTitle' data-aos="fade-up" data-aos-duration="1500">
                        <p>{title2}</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <Lottie
                            options={defaultOptions}
                            height={100}
                            width={100}
                        />
                    </div>

                </Container>



            </div>
        </Fragment>
    )
}

export default Introduction