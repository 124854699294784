import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.scss'
import { floatingData, services } from '../../../data'
import { BiCheckCircle } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import FloatingListSection from '../../global/floatingsection'

const AboutUsServices = () => {
    return (
        <div className='aboutusServiceWrapper'>
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceTitle' data-aos="fade-up" data-aos-duration="500">
                            Expert Cryptocurrency Recovery Services: Regain Access to Your Lost Holdings
                        </p>
                    </Col>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceSubTitle' data-aos="fade-up" data-aos-duration="1000">
                            Have you lost access to your cryptocurrency holdings? Our team of experts specializes
                            in recovering a diverse range of lost and inaccessible cryptocurrencies. Here are some typical
                            scenarios where our services can be beneficial:
                        </p>
                    </Col>
                </Row>
                <Row>
                    <div className='aboutusServicesContainer'>
                        {
                            services.map((item, index) => (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <Link to='/services' style={{ textDecoration: 'none' }}>
                                        <div key={index} data-aos="fade-up" data-aos-duration="1500" className='aboutusService'>
                                            {item.smallIcon}
                                            <p>{item.name}</p>
                                        </div>
                                    </Link>
                                </Col>
                            ))
                        }
                    </div>
                </Row >
            </Container >
        </div >
    )
}

export default AboutUsServices