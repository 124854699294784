import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'
import { testimonials } from '../../../data'
import Rating from '../rating'
import quote from '../../../assets/images/quote.svg';
import { shuffleArray } from '../../../hooks/shuffleArray'




const Testimonials = ({ title }) => {
    return (
        <div className='testimonialWrapper'>
            <div className='testimonialTitleContainer' data-aos="fade-up" data-aos-duration="500">
                <p>{title}</p>
            </div>
            <div className="testimonialsContainer">

                {shuffleArray(testimonials).map(testimonial => (
                    <div className='testimonialContainer'>
                        <div className='testimonialHeaderContainer'>
                            <img src={quote} />
                            <p>{testimonial?.description}</p>
                        </div>
                        <div className='testimonialBodyContainer'>
                            <img src={testimonial.avatar} width={70} height={70} />
                            <div className='testimonialNameRatingContainer'>
                                <p>{testimonial.name}</p>
                                <Rating rating={testimonial.rating} />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default Testimonials