import React, { Fragment } from 'react'
import './styles.scss'
import { Col, Container, Row } from 'react-bootstrap';
import cryptoImage from '../../../assets/images/cryptoImage.png'

const AboutUsDescription = () => {


    return (
        <Fragment>
            <div className='aboutUsDescriptionWrapper'>

                <Container className='aboutUsDescriptionImageContainer'>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                            <div className='aboutUsDescriptionFirstTitle' data-aos="fade-up" data-aos-duration="500">
                                <p>Cryptocurrency Recovery Specialists</p>
                            </div>
                            <div className='aboutUsDescriptionSecondTitle' data-aos="fade-up" data-aos-duration="1500">
                                <p>Specializing in recovering lost cryptocurrency funds, we are experts in helping you retrieve your assets with the highest success rate.With our specialized knowledge and experience, we excel in the recovery of lost cryptocurrency, offering you the best chance to regain your funds.Put your trust in our team of professionals who specialize in recovering lost cryptocurrency. We have the expertise to assist you in reclaiming your funds.As trusted experts in the field, we focus on recovering lost cryptocurrency funds, utilizing our skills and knowledge to increase the likelihood of successful recovery.Partner with our team of experts who are dedicated to restoring lost cryptocurrency. We have the skills and resources to maximize your chances of recovering your funds.With the rapid growth of the cryptocurrency market, it is not uncommon for individuals to encounter various challenges that can lead to the loss of their digital assets. Whether it be a forgotten password, a compromised wallet, a fraudulent transaction, or any other unfortunate circumstance, we are here to assist you in recovering your lost cryptocurrency.</p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                            <img src={cryptoImage} className='cryptoImage' />
                        </Col>
                    </Row>


                </Container>



            </div>
        </Fragment>
    )
}

export default AboutUsDescription