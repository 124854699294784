import React, { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import SignInFields from './signInFields'
import Introduction2 from '../../components/global/introduction2'
import signinImage from '../../assets/images/signin.jpg'

const SignIn = () => {
    return (
        <Fragment>
            <Introduction2
                image={signinImage}
                children={
                    <SignInFields />
                }
            />
        </Fragment>
    )
}

export default SignIn