import React, { Fragment } from 'react'
import './styles.scss'
import { Col, Container, Row } from 'react-bootstrap';

const Introduction = ({
    children,
    image
}) => {


    return (
        <Fragment>
            <div className='introduction2Wrapper'>
                <Container className='introduction2Container'>
                    <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='introduction2ChildrenContainer'>
                                {children}
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='introduction2Image'>
                                <img src={image} />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </Fragment>
    )
}

export default Introduction